@import '../../assets/styles/helpers/responsive';
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/utils';

.btn-refresh {
  background: #DA291C;
  border-radius: 4px;
  border-style: unset;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: $font-bolder;
  height: 45px;
  outline: none;
  width: 100%;
}

.cupon-container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgb(27 33 56 / 6%);
  padding: 15px 15px 20px 15px;
}

.head-p {
  color: #ffffff;
  display: block !important;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  position: absolute;
  right: 15px;
  top: 30px;
  visibility: hidden;

  @media only screen and (min-width: 1200px) {

    visibility: initial;
  }
}


.wrapper {
  display: grid;
  column-gap: 30px;
  grid-template-areas:
    "title"
    "content"
    "totalizer"
    "footer";
  background-color: $gris-fondo;
  padding: 0 15px;
  grid-template-columns: 1fr;

  @include desktop {
    display: grid;
    padding: 0 35px;
    grid-template-columns: 762px 377px;
    grid-template-areas:
      "title title"
      "content totalizer"
      "footer footer";
    justify-content: center;
  }

  @include monitor-large {
    display: grid;
    padding: 0 35px;
    grid-template-columns: 762px 377px;
    grid-template-areas:
      "title title"
      "content totalizer"
      "footer footer";
    justify-content: center;
  }

  @include mobile {
    display: block;
  }
}

.header {
  color: white;
  background: linear-gradient(180deg, #02498E 0%, #00285C 96.15%);
  height: 71px;
}

.footer {
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 5px;
  text-align: center;

  @include mobile {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #ffffff;
  }
}

.content {
  grid-area: content;
}

.totalizer {
  grid-area: totalizer;
  width: 100%;

  @include desktop {
    height: 550px;
    overflow-y: hidden;
    position: sticky;
    right: 0;
    scroll-behavior: smooth;
    top: 0px;
  }

  @include mobile {
    background-color: transparent;

  }
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.title-section {
  display: flex;
  gap: 10px;
  grid-area: title;
  position: relative;

  &__header {
    color: $gris1;
    font-size: 20px;
    font-style: normal;
    font-weight: $font-bold;
    line-height: 34px;
    margin: 0;
    padding: 10px 0;
  }

  &__back-to-cart {
    color: #282828;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-regular;
    line-height: 21.79px;
    margin-left: 540px;
    margin-top: 11px;
    padding: 10px 0;
    text-decoration-line: underline;

    @include mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @include mobile {
    justify-content: space-between;
  }
}

.unimarc-logo {
  background-image: url("../../assets/images/logoAlvi.svg");
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  height: 76px;
  justify-content: center;
  margin: auto;
  width: 71px;

  @include desktop {
    margin: 0px 10px 0px 30px;
    justify-content: flex-start;
  }
}

.mastercard-logo {
  background: url(../../assets/images/mastercard.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  height: 32px;
  width: 50px;
}

.diners-logo {
  background: url(../../assets/images/dinners.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90px;
  height: 30px;
  width: 90px;
}

.magna-logo {
  background: url(../../assets/images/magna.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90px;
  height: 30px;
  width: 90px;
}

.americanexpress-logo {
  background: url(../../assets/images/americanExpress.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  height: 20px;
  width: 50px;
}

.redcompra-logo {
  background: url(../../assets/images/redcompra.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
  height: 20px;
  width: 80px;
}

.visa-logo {
  background: url(../../assets/images/visa.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  height: 20px;
  width: 55px;
}

.trash-icon {
  background-image: url("../../assets/images/trash.svg");
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: 50%;
  border: 0;
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
}

.horizontal-line {
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: #C4C4C4;
  margin-bottom: 5px;
}

.btn-change {
  background: white;
  /* SMU/Red */
  border: 1px solid $blue-alvi-radius;
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: $font-bold;
  font-size: 14px;
  color: $blue-alvi-radius;
  height: 35px;
  width: 100%;
  left: 811px;
  top: 508px;
}


.edit-action {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 5px;

  &__text {
    font-size: 0.8rem;
    text-decoration: underline;
    margin: 0;

  }
}

.edit-icon {
  background-image: url("../../assets/images/edit.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  height: 23px;
  width: 18px;
}

.close-icon {
  background-image: url("../../assets/images/close.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  height: 23px;
  width: 18px;
}

.edit-icon-grey {
  background-image: url("../../assets/images/edit-grey.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  height: 23px;
  width: 18px;
  margin-top: 12px;
}

.x-icon {
  background-image: url("../../assets/images/x-icon.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  height: 23px;
  width: 18px;
  margin-top: 4px;

  &:hover {
    cursor: pointer;
  }
}

.x-icon-red {
  background-image: url("../../assets/images/x-icon-red.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  height: 23px;
  width: 18px;
  margin-top: 4px;

  &:hover {
    cursor: pointer;
  }
}

.checkOut {
  .loading {
    display: block;
    width: 30px;
    height: 30px;
    margin: 40px auto 0;
    background: url('../../assets/images/loading-red.svg') no-repeat 0 0;
    background-size: 100% auto;
    animation-name: rotating;
    animation-duration: 0.9s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.dialog-form {
  padding: 20px;

  &>div {
    margin: 10px 0;
  }
}

.dialog-btn-wrapper {
  padding: 15px 0;
  background: #FFFFFF;
  position: sticky;
  position: -webkit-sticky;
  /* Safari */
  bottom: 0;
  z-index: 9999;
}

/* Skeleton for Loading */

.placeholder-item-profile {
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 6px;
  height: 190px;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.placeholder-item-profile::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.placeholder-item-shipping {
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 6px;
  height: 234px;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.placeholder-item-shipping::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.placeholder-item-payment {
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 6px;
  height: 454px;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.placeholder-item-payment::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.placeholder-item-totalizer {
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 6px;
  height: 362px;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.placeholder-item-totalizer::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.placeholder-item-totalizer-temp {
  border-radius: 6px;
  height: 280px;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  margin-bottom: 15px;
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}

.icon-error {
  background-image: url("../../assets/images/error.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 50%;
  border: 0;
  height: 15px;
  width: 15px;
  margin: 0;
  padding: 0;
}

.error-icon {
  background-image: url("../../assets/images/errorIcon.svg");
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 60px;
  position: relative;
  top: -28px;
  margin-bottom: -45px;
}

.icon-delete {
  background-image: url("../../assets/images/delete.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  border: 0;
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;

  @include mobile {
    width: 50px;
  }
}

.icon-lock {
  background-image: url("../../assets/images/lock.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 50%;
  border: 0;
  height: 15px;
  width: 15px;
  margin: 0;
  padding: 0;
}

.loader424-container {
  position: absolute;
  width: 93%;
  top: 50%;

  @include mobile {
    top: 45%;
  }

  @include tablet {
    width: 97%;
    top: 30%;
  }

  @include tablet-large {
    width: 97%;
    top: 30%;
  }
}

.loader424-text {
  max-width: 411px;
  margin: 0 auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: $gray-text;

  @include desktop {
    font-weight: 700;
    font-size: 24px;
  }

  @include mobile {
    max-width: 100%;
  }
}

//totalizers Loaader

.totalizer-rowLoader {
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 6px;
  height: 550px;
  position: relative;
  overflow: hidden;
  width: 100%;

  @include mobile {
    height: 250px;
  }
}
.totalizer-rowLoaderBottom {
  @include mobile {
    bottom: 40px;
    margin-top: 50px;
  }
}

.totalizer-rowLoader::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}