@import '../../../../assets/styles/helpers/variables';
@import '../../../../assets/styles/helpers/responsive';

.container {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
  margin-bottom: 15px;
  padding: 10px 25px;
  @include desktop {
    padding-bottom: 20px;
  }

  @include mobile {
    padding: 10px 20px;
  }

  @include mobile-md {
    padding: 10px 15px;
  }

  &__title {
    font-size: 16px;
    font-weight: $font-bold;
    margin: 0;
    padding: 10px 0;
  }

  &__content {
    margin-left: 10px;
  }
}