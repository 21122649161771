@import '../../../../assets/styles/base';

.invoice-preview-container {
    background-color: $gray-bg;
    padding: 10px;
}
.invoice-preview-content {
    display: flex;
    padding-top: 10px;
    &__text {
        margin-right: 5px;
    }
}