@import "../../assets/styles/base";

.title-terms-and-conditions {
  font-size: 20px !important;
  color: #282828 !important;
}

.dialog-alert {
  overflow: hidden;
  
  &__content {
    padding: 30px;

    @include mobile {
      padding: 0 20px;
      width: auto;
    }
  }

  &__title-section {
    display: flex;
    text-align: center;

    @include mobile {
      padding: 20px 0px;
    }

    @include tablet {
      padding: 30px 0;
    }

    @include mobile-md {
      padding-left: 0px;
    }

    @include tablet-large {
      padding: 40px 0;
    }
  }

  &__title-text {
    font-weight: $font-bold;
    font-size: 1.2rem;
    margin: auto;
    margin-bottom: 9px;

    @include mobile {
      width: 60vw;
    }

    @include mobile-md {
      width: 96vw;
    }
  }

  &__btn-wrapper {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
    width: 85%;

    @include mobile {
      margin-top: 20px;
    }

    @include tablet {
      margin-top: 20px;
    }
  }

  &__btn-wrapper .secondary {
    margin-top: 10px;
  }

  &__body-text {
    text-align: center;
    margin-top: 10px;

    @include tablet {
      margin-top: -10px;
    }
    @include mobile {
      margin-top: -10px;
    }
  }
}

.icon-error {
  @include tablet {
    height: 65px !important;
    padding-right: 20px !important;
  }
}
