@mixin mobile-md {
  @media only screen and (min-width: 320px) and (max-width: 400px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 711px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 712px) and  (max-width: 1023px){
    @content;
  }
}

@mixin tablet-large {
  @media only screen and (min-width: 1024px) and (max-width: 1199px){
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1200px) and (max-width: 1999px) {
    @content;
  }
}

@mixin monitor-large {
  @media only screen and (min-width: 2000px) {
    @content;
  }
}