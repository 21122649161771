@import '../../assets/styles/base';
@import '../../assets/styles/helpers/responsive';

.btn {
    border-radius: 24px;
    height: 45px;
    font-size: 1.1rem;
    font-weight: $font-bolder;
    width: 100%;
    border-style:unset;
    outline: none;
    &.enabled {
        background: linear-gradient(180deg, #02498E 0%, #00285C 96.15%);
        color: #FFFFFF;
        cursor: pointer;
        
    }
    &.disabled {
        background: #C4C4C4;
        color: #666666;
    }
    &.secondary {
        background: #FFFFFF;
        color: $blue-alvi-radius;
        border: 1px solid $blue-alvi-radius;
    }
}

.btn-notFound {
    @include mobile {
        width: 93%;
    }
}