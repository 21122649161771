@import "../../assets/styles/helpers/responsive";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/utils";

.wrapper_notFound {
  background-color: #e5e5e5;
  column-gap: 30px;
  display: grid;
  height: calc(100vh - 130px);
  grid-template-areas:
    "title"
    "content"
    "totalizer"
    "footer";
  grid-template-columns: 1fr;
  padding: 0 15px;

  @include desktop {
    display: grid;
    padding: 0 35px;
    grid-template-columns: 762px 377px;
    grid-template-areas:
      "title title"
      "content totalizer"
      "footer footer";
    justify-content: center;
  }
  @include monitor-large {
    display: grid;
    padding: 0 35px;
    grid-template-columns: 762px 377px;
    grid-template-areas:
      "title title"
      "content totalizer"
      "footer footer";
    justify-content: center;
  }

  @include mobile {
    padding-top: 30px;
    height: 650px;
    display: block;
  }
}

.text-lose {
  color: #666666;
  font-family: $font-sans;
  font-size: 60px;
  font-style: normal;
  font-weight: bold;
  height: 82px;
  left: 120px;
  letter-spacing: 0.03em;
  line-height: 82px;
  position: absolute;
  top: 251px;
  width: 500px;
  @include mobile {
    width: 311px;
    height: 53px;
    left: 32px;
    top: 120px;
    font-size: 38.1818px;
    line-height: 52px;
  }
}

.text-lose2 {
  position: absolute;
  width: 473px;
  height: 113px;
  left: 120px;
  top: 353px;
  font-family: $font-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
  color: #666666;

  @include mobile {
    width: 299.04px;
    height: 73.58px;
    left: 32px;
    top: 178.42px;
    font-size: 20.3636px;
    line-height: 28px;
  }
}
.booton-lose {
  position: absolute;
  width: 335px;
  height: 45px;
  left: 120px;
  top: 500px;
  font-family: $font-sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: white;

  @include mobile {
    text-align: center;
    top: 600px;
    left: 40px;
  }
  @media only screen and (max-width: 375px) {
    left: 20px;
  }
}

.header {
  background-color: white;
  height: 71px;
}

.footer {
  background-color: $gris-fondo;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: center;

  @include mobile {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $gris-fondo;
  }
}

.content {
  grid-area: content;
}

.title-section {
  grid-area: title;

  &__header {
    font-size: 20px;
    font-weight: $font-bold;
    padding: 10px 0;
    line-height: 34px;
    font-style: normal;
    color: $gris1;
    margin: 0;
  }
}

.alvi-logo-notFound {
  background-image: url(https://checkout.alvi.cl/static/media/logoAlvi.2fbdf987.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  display: flex;
  flex-flow: row wrap;
  height: 55px;
  width: 75px;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  margin: auto;
  @include desktop {
    margin: 10px 30px;
    justify-content: flex-start;
  }
  @include mobile {
    padding-top: 20px;
  }
}

.not-found {
  background-image: url("../../assets/images/notFound.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: sticky;
  width: 730px;
  height: 574.9px;
  left: 810px;
  top: 176px;
  @include desktop {
    margin: 10px 30px;
    justify-content: flex-start;
  }
  @include mobile {
    background-image: url("../../assets/images/notFound2.svg");
    width: 311px;
    left: 32px;
    top: 400px;
    height: 480px;
  }
  @media only screen and (max-height: 800px) {
    height: 500px;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
