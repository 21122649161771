@import '../../../../assets/styles/base';

.oneclick-list-container {
    display: flex;
    justify-content: start;
    overflow-x: auto;
    padding: 10px 0;

    @include mobile {
        max-width: 88vw;
    }

    @include tablet {
        max-width: 85vw;
    }

    @include tablet-large {
        max-width: 85vw;
    }
}

.oneclick-list {
    display: flex;

    @include mobile {
        max-width: 20vw;
    }
}

.empty-card-message {
    width: 60%;

    @include mobile {
        width: 100%;
    }
}