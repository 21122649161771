@import '../../../../assets/styles/helpers/responsive';
@import '../../../../assets/styles/helpers/variables';

.profile {
    background-color: white;
    .title{
        font-size: 25px;
    }

}

.personal-info {
    margin: 0;
    padding: 10px 0;
    & p {
        margin: 5px 0;
    }
}

.phone-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    margin-bottom: 24px;
    color: $gray-dark;
    &__number-text{
        color: $gris1;
        size: 12px;
        font-weight: 600;
    }
    @include mobile {
        height: 100%;
        margin-top: 11px;
        flex-direction: column;
    }
    
    &__number {
        width: 100%;
        max-width: 400px;
        @include mobile {
            align-self: baseline;
        }
    }

    &__notice {
        width: 100%;
        margin-left: 30px;
        text-align: left;
        margin-top: 63px;
        margin-right: 176px;

        @include mobile {
            flex-direction: column;
            margin-left: 0px;
            margin-right: auto;
            margin-top: 20px;
        }
    }
}