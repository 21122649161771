@import '../../../../assets/styles/base';

.without-stock {
    &__li {
        color: $gray-dark;
        list-style:none;
        padding-top: 5px;
    }


    &__ul {
        padding: 0;
        margin: 0;
        height: 50vh;
        overflow-y:auto; /* Hide scrollbars */
    }
    @include mobile {
        &__ul {
            padding: 0;
            margin: 0;
            max-height: 40vh;
            overflow-y:auto; /* Hide scrollbars */
        }
        &__ul::-webkit-scrollbar {
            display: none;
        }
    }
    &__text {
        min-height: 30px;
    }
    &__total {
        font-weight: $font-bold;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }
}