@import '../../../../assets/styles/base';



.busqueda {
  display: flex;
  align-items: initial;

  ul {
    padding: 5px 5px;
    margin-top: -10px;
    margin-bottom: 5px;
    display: inherit;
  }

  span {
    padding-left: 5px;
    padding-right: 3px;
    font-weight: bold;
    vertical-align: 2px;
    font-size: 13px;
    color: #09489b;
    cursor: pointer;
  }

  &__input {
    padding-right: 5px;
    padding-left: 5px;
  }

  &__tag {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 8px;
    list-style: none;
    padding: 13px 13px;
    color: #282828;
    background: #e5e5e5;
    height: 46px;
    width: max-content;

    @include mobile {
      background: #f7f7f8;
      height: auto;
      box-shadow: 0px 4px 8px rgb(27 33 56 / 6%);
      border-radius: 12px;
      border: 1px solid #6666664f;
      height: auto;
    }

  }
}

.button_cupon {
  border-radius: 0px;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  height: 50px !important;
  width: 80px;
}

.text_cupon {
  height: 22px;
  padding-left: 16.71%;
  padding-right: 55.44%;
  padding-bottom: 193px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #282828;
}

.active-border {
  border: 1px solid $gray-dark;
}

.temrinos_condiciones {
  overflow: auto;
  height: 367px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-align: justify;
  padding-right: 20px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 6px;
  }

}

.temrinos_condiciones_p {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}

.terms-btn-wrapper {
  padding: 10px 30px;
  background: #ffffff;

  @include mobile {
    position: sticky;
    position: -webkit-sticky;
    /* Safari */
    bottom: 0;
    z-index: 9999;
  }
}

/* Refactor CSS */

.totalizer-content {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
  padding: 20px 25px;
  position: sticky;
  top: 0;
  z-index: 0;
  border-radius: 12px;
  margin-bottom: 15px;

  @include mobile {
    margin-bottom: 0px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
  }
}

.totalizerhr {
  border: 1px solid #E5E5E5;

}

.title-totalizer {
  padding: 10px 0;
  font-size: 16px;
  font-weight: $font-bold;

  & .number_products {
    font-weight: $font-medium;
  }
}

.btn-look-as-a-link {
  background: none;
  border: none;
  padding: 7.5px 0;
  text-decoration: underline;
  cursor: pointer;
  color: black;

  &:link {
    color: black;
  }

  &:visited {
    color: black;
  }

  &:hover {
    color: black;
  }

  &:active {
    color: black;
  }

  & .back-to-cart {
    padding: 0;
  }
}

.link_underline {
  color: #282828;
  font-weight: 600;
  padding: 0;
}

.wrapper__terms-and-conditions {
  align-items: center;
  display: flex;
  margin: 25px 0;

  @include mobile {
    margin: 17px 0;
  }
}

.totalizer-row {
  display: flex;
  padding: 7.5px 0;
  justify-content: space-between;
}

.terms-and-conditions {
  color: #666;
  display: inline;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 0 13px;
  font-weight: 400;
  line-height: 16px;
}

.back-to-cart {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  align-items: center;

  @include mobile {

    padding-top: 0px;
    padding-bottom: 20px;
  }
}

.btn-pay-wrapper {
  padding: 5px 0;
}

.summary {
  @include mobile {
    margin-bottom: 180px;
    padding: 10px 0;
  }
}

.terms-and-pay {
  @include mobile {
    position: fixed;
    bottom: 34px;
    right: 0;
    left: 0;
    background-color: white;
    padding: 20px;
    padding-top: 10px;
  }

  label {
    margin-right: 0px !important;
  }
}

.cupon-logo {
  background: url(../../../../assets/images/Vector.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  height: 30px;
  width: 30px;
  padding: 10px;
}

.cupon-delete {
  background: url(../../../../assets/images/cupon-delete.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  padding: 10px;
  cursor: pointer;
}

.papperCoupon {
  align-items: center;
  border: 1px solid #666666;
  border-radius: 4px !important;
  box-sizing: border-box;
  display: flex;

  @include mobile {
    border-radius: 8px !important;
    width: auto;
  }
}