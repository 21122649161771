@import '../../../../assets/styles/base';

.registration-alert {
  align-items: center;
  background: #3B3B3B;
  border-radius: 12px;
  display: flex;
  height: 52px;
  justify-content: flex-start;
  margin-bottom: 5px;
  min-height: 45px;
  padding: 4px 14px;
  position: absolute;
  z-index: 1;

  @include monitor-large {
    margin-bottom: 5%;
  }
  @include desktop {
    margin-bottom: 5%;
  }

  @include mobile {
    left: 4%;
    position: fixed;
    right: 4%;
    top: 10%;
  } 
}

.success-toast {
  background-image: url("../../../../assets/images/icon-success.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 0;
  height: 30px;
  margin: 0;
  padding: 0;
  width: 30px;

  @include mobile {
    width: 40px;
  }
}

.error-toast {
  background-image: url("../../../../assets/images/icon-error.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 0;
  height: 30px;
  margin: 0;
  padding: 0;
  width: 30px;

  @include mobile {
    width: 40px;
  }
}

.x-toast {
  background-image: url("../../../../assets/images/icon-x.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  border: 0;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  width: 24px;

  &:hover {
    cursor: pointer;
  }

  @include mobile {
    width: 40px;
    padding: 0;
  }
}

.registration-message {
  color: white;
  font-size: 14px;
  padding: 0px 15px;
  width: 90%;
}
