@import '../../../../../assets/styles/helpers/variables';

$border-thickness: 1px;

.window-editor {
    padding: 0;
    padding-left: 40px;
}

.date {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 5px;
}

.calendar {
    width: auto;
    padding-bottom: 5px;
}

.week {
    display: flex;
}

.slot {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    width: 80px;
    max-width: 80px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    font-size: 1.1rem;
    cursor: pointer;
}

.header__week {
    font-size: 1rem;
    font-weight: $font-bold;
    text-align: center; 
    height: 35px;

    &__day {
        margin-right: 10px;
    }

    &__title {
        align-items: center;
        display: flex;
        margin-right: 20px;
        max-width: 110px;
        width: 100px;
    }
}

.cost {
    background: rgb(249, 249, 249);
    border: 1px solid rgb(196, 196, 196);
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 10px 10px 0px;
    text-align: center;
    vertical-align: middle;
}

.unavailable-line {
    border: 1px solid $gris3;
    transform: 
        translateY(13px)
        rotate(-10deg);
}

.unavailable {   
    cursor:auto;
}

.active {
    background: white;
    border: 1px solid $blue-alvi-radius;
    color: $blue-alvi-radius;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: $font-bold;

}

.time-window{
    text-align: left;
    margin: 10px 0;
    font-size: 1rem;
    max-width: 130px;
}