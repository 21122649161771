@import '../../../../assets/styles/base';


.option-container {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
    margin: 15px 0px;

    .horizontal-line {
        border: 1px solid #E5E5E5;
        margin: 5px;
        margin-left: 40px;

        @include mobile {
            margin: 10px 0;
        }
    }    

    @include mobile {
        margin: 15px 0;
        padding: 20px;
    }
}
.type {
    display: flex;
    align-items: center;

    @include mobile {
        margin-left: -10px;
    }

    &__text {
        justify-content: center;
        align-items: center;
        display: flex;
        font-weight: bold;
        padding: 0 5px;
    }
}

.address-content {
    margin: 0;
    padding: 0 40px;

    @include mobile {
        padding: 0;
    }
}

.address {
    margin: 0;
    display: flex;
    flex-direction: row;

    &__text {
        align-items: center;
        display: block;
        padding: 5px 5px;
        margin: 0;
        .edit-action {
            position: relative;
            top: 6px;
        }
    }

    @include mobile {
        flex-direction: column;
    }
}

.delivery-window {
    display: flex;
    margin: 0;
    padding: 5px 0;

    &__text {
        display: flex;
        padding: 5px 0;
    }

    @include mobile {
        flex-direction: column;
    }
}

.icon-shop {
    background-image: url("../../../../assets/images/shop.svg");
    background-size: 26px;
    height: 26px;
    width: 26px;
}

.icon-truck {
    background-image: url("../../../../assets/images/truck-icon.svg");
    background-size: 26px;
    height: 26px;
    width: 26px;
}

.icon-clock {
    background-image: url("../../../../assets/images/clock.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 50%;
    border: 0;
    height: 15px;
    width: 15px;
    margin: 0;
    padding: 0;
}

.change_address {
    display: inline-flex;
    margin-left: 5px;
  }