@import '../../../../assets/styles/base';

.invoice-option {
  display: flex;
}

.giftCardContainer {
  display: flex;
  flex-grow: 3;
}

.payment-text {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
}

.container-pago {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
  box-sizing: border-box;
  margin: 15px 0;
  padding: 20px;

  &:hover {
    cursor: pointer;
  }
}

.margin-radio {
  margin-right: -16px;
}

.button_giftcard {
  background: #e5e5e5 !important;
  border-radius: 24px;
  box-sizing: border-box;
  height: 38px;
  width: 100%;
}

.button_giftcard-mobile {
  background: #e5e5e5 !important;
  box-sizing: border-box;
  height: 38px;
  margin-top: 8px;
}

.debito {
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  margin-top: 1px;

  @include mobile {
    display: block;
    margin-top: 0px;
  }
}

.tooltip {
  color: #ffffff;
  font-size: 14px !important;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.margin-tooltip {
  margin-left: 20px;
  margin-top: 6px;
}

.logo-transbank-oneClick {
  background: #fff url("../../../../assets//images/LogoWebpay-oneclick.svg") center center no-repeat;
  height: 32px;
  margin-right: 16px;
  margin-top: 4px;
  width: 100px;
}

.logo-transbank-webPay {
  background: #fff url("../../../../assets//images/LogoWebpay-plus.svg") center center no-repeat;
  height: 32px;
  margin-right: 14px;
  margin-top: 4px;
  width: 100px;
}

.logo-transbank-giftCard {
  background: #fff url("../../../../assets//images/giftcard.svg") center center no-repeat;
  height: 32px;
  margin-right: 14px;
  margin-top: 4px;
  width: 40px;
}

.content-input {
  border-radius: 100%;
  height: 30px;
  margin-top: 10px;
  width: 30px;
}

.box-item {
  align-items: stretch;
  display: flex;
}

.push-item {
  display: inline-flex;
  margin-left: auto;

  @include mobile {
    width: 100%;
  }
}

.push-item-aspa {
  font-size: 25px;
  font-weight: bold;
  padding-left: 3px;
}

.push-item-aspa-mobile {
  font-size: 25px;
  font-weight: bold;
  padding-left: 2px;
}

.switch-wrapper {
  margin-top: 4px;
}

.classTooltip {
  max-width: 16% !important;
}

.webpay-plus {
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 0;
}

.warn-message {
  color: #da291c;
  font-weight: 600;
  line-height: 2;
}

.padding-text {
  padding-left: 32px;
}

.text-giftcard {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  text-align: left;
}

.textfield {
  margin-left: 36px;
  width: 120ch;

  @include mobile {
    margin-left: 0px;
    width: 100%;
  }
}

.input-giftcard-numero {
  width: 95%;

  @include mobile {
    width: 100%;
  }
}

.input-giftcard-numero-mobile {
  padding-bottom: 10px !important;
  width: 100%;
}

.input-giftcard-pin {
  width: 30%;
}

.input-giftcard-pin-mobile {
  margin-top: 10px !important;
  width: 100%;
}

.color-error {
  background: #f80000 !important;
  border-radius: 24px;
  box-sizing: border-box;
  color: white !important;
  height: 38px;
  width: 100%;
}

.margin-giftcard {
  margin-top: -39.8px !important;
  padding-left: 490px;

  @include mobile {
    margin-top: 0px !important;
  }

  @media only screen and (min-width: 1300px) {
    padding-left: 510px;
  }

  @media only screen and (min-width: 1400px) {
    padding-left: 560px;
  }

  @media only screen and (min-width: 1500px) {
    padding-left: 650px;
  }

  @media only screen and (min-width: 1600px) {
    padding-left: 750px;
  }
}

.margin-giftcard-mobile {
  margin-top: -43px !important;
}

.icon-clear-pin {
  margin-left: -44px !important;
  margin-top: -6px !important;
  position: relative !important;
}

.icon-clear-num {
  margin-left: -72px !important;
  margin-right: 30px !important;
  margin-top: -7px !important;
  position: relative !important;

  @include mobile {
    margin-left: 246px !important;
    margin-top: -56px !important;
  }
}

.textoError {
  color: #f80000 !important;
}

.textoOk {
  color: #666666 !important;
}

.alert-giftcard {
  align-items: center;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid $gris3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  height: 40px;
  left: 84px;
  position: fixed;
  right: 1270px;
  top: 560px;
  width: 30%;
  z-index: 1;

  @include mobile {
    height: 40px;
    left: 44px;
    position: static;
    top: 130px;
    width: 80vw;
  }

  @include monitor-large {
    left: 100px;
    right: 1900px;
    top: 520px;
  }

  & .icon-style {
    margin-left: 20px;

    @include mobile {
      margin-left: 0px;
    }
  }

  & .message {
    margin: 0;
    padding-left: 26px;
    width: 342px;

    @include mobile {
      padding-left: 44px;
      width: 340px;
    }

    @include mobile-md {
      padding-left: 38px;
      width: 250px;
    }
  }
}

@media only screen and (min-width: 340px) and (max-width: 380px) {
  .logo-transbank {
    margin-left: -20px;
  }

  .margin-tooltip {
    margin-left: 35px;
  }

}

$switch-active-bg-color: #02498E !default;
$switch-inactive-bg-color: #e8e8e8 !default;
$switch-knob-bg-color: #ffffff !default;
$switch-disabled-knob-bg-color: #f8f8f8 !default;

.switch {
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;

  .switch-label {
    cursor: pointer;
  }

  .switch-input {
    -ms-flex-align: center;
    align-items: center;
    appearance: none;
    background-color: $switch-inactive-bg-color;
    border-radius: 3rem;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 30px;
    padding: 0.125rem;
    transition: background-color 0.75s ease, box-shadow 0.35s ease-in;
    width: 50px;

    &:before {
      background-color: $switch-knob-bg-color;
      border-radius: 1rem;
      border: 1px solid rgba(0, 0, 0, 0);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.32);
      content: "";
      display: inline-block;
      height: 24px;
      position: relative;
      transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.175);
      width: 25.6px;
    }

    &:active {
      &:not(:disabled) {
        &:before {
          width: 1.75rem;
        }
      }
    }

    &:checked {
      background-color: $switch-active-bg-color;
      box-shadow: inset 0 0 0 1px $switch-active-bg-color;

      &:before {
        transform: translate3d(calc(100% - 0.4rem), 0, 0);
      }

      &:active {
        &:not(:disabled) {
          &:before {
            margin-left: -0.5rem;
          }
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
      user-select: none;

      &:before {
        background-color: $switch-disabled-knob-bg-color;
      }
    }
  }
}

.text-bill {
  font-size: 16px;
  font-weight: 400;
}

.text-bill span {
  font-weight: bold;
}

.switch-bill {
  align-items: right;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.container-transfer {
  align-items: center;
  background: #FFFFFF;
  border-radius: 9px;
  box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
  display: flex;
  height: 64px;
  padding: 0 24px;
}

.image_etpay {
  height: 22px;
  margin-left: -15px;
  width: 44px;
}

.container-transfer__method_payment {
  color: #282828;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 0 15px;
}

.disabled_text {
  color: #C4C4C4;
}

.disabled_image {
  mix-blend-mode: luminosity;
}

.warning-icon {
  height: 16px;
  margin-left: 10px;
  width: 16px;
}


.container-transfer__tooltip {
  align-items: center;
  display: flex;
  position: relative;
}

.tooltiptext {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  span {
    padding: 15px 20px 10px 20px;
  }
}

.container-transfer__tooltip .tooltiptext {
  background-color: #555;
  border-radius: 6px;
  bottom: 115%;
  display: flex;
  height: 132.5px;
  margin-left: -60px;
  opacity: 0;
  padding: 15px auto 10px auto;
  position: absolute;
  right: -15px;
  text-align: center;
  transition: opacity 0.3s;
  visibility: hidden;
  width: 291px;
  z-index: 1;
}

.container-transfer__tooltip .tooltiptext::after {
  border-color: #555 transparent transparent transparent;
  border-style: solid;
  border-width: 9px;
  content: "";
  left: 50%;
  margin-left: 115px;
  position: absolute;
  top: 100%;
}

.container-transfer__tooltip:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.container-transfer__tooltip .tooltiptext_visible {
  opacity: 1;
  visibility: visible;
}

.alert-danger {
  padding: 10px 40px;

  .alert-danger__header {
    border-bottom: 1px solid #E5E5E5;
    margin: 10px 0px;
    padding: 10px 0px;

    h2 {
      color: #282828;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin: 0;
    }
  }

  .paragraph_description {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 23px;
  }
}

.btn-close {
  border-radius: 50%;
  border: none;
  height: 40px;
  outline: none;
  position: absolute;
  right: 20px;
  background-color: transparent;
  top: 15px;
  width: 40px;
}

.overline {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9;
  background: rgba(40, 40, 40, 0.4);
  top: 0;
  left: 0;
}

@media only screen and (max-width: 711px) {
  .container-transfer__method_payment {
    font-size: 14px;
  }

  .disabled_text {
    font-size: 14px;
  }
}