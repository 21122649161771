@import '../../../../assets/styles/base';

.loader-card{
    color: $blue-alvi-radius !important;
    left: 85px;
    margin-top: 3px;
    position: absolute;
    top: 40px;
}
.oneclick-card {
    background: $gray-bg;
    border-radius: 15.4468px;
    border: 1.28723px solid $gris3;
    box-shadow: 0px 0px 6.43617px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    height: 121px;
    margin-right: 22px;
    position: relative;
    width: 219px;

    &__card-number {
        color: $gray-dark;
        font-size: 16px;
        padding: 5px 0;
    }
    &__content {
        margin-top: -20px;
        padding: 0 15px;
    }

    @include mobile {
        flex: 0 0 auto;
        max-width: 200px;
    }
}

.active {
    background: #DEEAFA;
    border: 1px solid #02498E;
}


.trash-icon__circle {
    background: $gris4;
    border-radius: 50%;
    cursor: pointer;
    height: 36px;
    width: 36px;
}

.trash-icon__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;
}

.trash-icon__circle.icon-active {
    background: #D7E0FE;
}

.trash-icon-style {
    background-size:15.45px !important;
    height: 15.45px !important;
    margin: auto !important;
    padding-top: 18.45px !important;
    width: 15.45px !important;
}
