@import '../../../../assets/styles/base';

.who-receives {
    align-items: start;
    display: flex;
    padding: 0px 40px;

    .title {
        font-size: 16px;
        font-weight: 700;
        margin-top: 7px;
        padding-right: 25px;
        white-space: nowrap;
    }

    @include mobile {
        align-items: flex-start;
        flex-direction: column;
        font-size: 14px;
        padding: 0;

        .title {
            font-size: 14px;
            padding-right: 25px;
        }
    }
}