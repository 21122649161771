@import '../../../../assets/styles/helpers/variables';

.window-editor {
    padding: 0;
    padding-left: 40px;
}

.text-mobile {
    padding-top: 4px;
    margin-top: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #282828;
}

.text-select {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #282828;
    padding-left: 20px;
}

.header__week {
    font-size: 1rem;
    font-weight: $font-bold;
    text-align: center; 
    height: 35px;

    &__day {
        margin-right: 10px;
    }

    &__title {
        align-items: center;
        display: flex;
        margin-right: 20px;
        max-width: 110px;
        width: 100px;
    }
}

.week {
    display: flex;
}

.slot {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    width: 80px;
    max-width: 80px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    font-size: 1.1rem;
    cursor: pointer;
}

.time-window{
    text-align: left;
    margin: 10px 0;
    font-size: 1rem;
    max-width: 130px;
}


.horario {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #282828;
}

#wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
}


#container {
    display: flex;
    width: 400px;
}

.cost {
    background: $gris-fondo;
    border: 1px solid $gris3;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    margin: 10px;
    box-sizing: border-box;
    margin-left: 0px;
}

.unavailable-line {
    border: 1px solid $gris3;
    transform: 
        translateY(13px)
        rotate(-10deg);
}

.unavailable {   
    cursor:auto;
}

.active {
    background: white;
    border: 1px solid $blue-alvi-radius;
    color: $blue-alvi-radius;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-bold;

}

.item {
    float:left;
    width: 64px;
    height: 50px;
    left: 119px;
    top: 610px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    vertical-align: middle;
    font-size: 14px;
    cursor: pointer;
}

.select-list {
    width: 100%;
    height: 40px;
    left: 45px;
    top: 703px;
    background: #FFFFFF;
    border: 1px solid #9D9D9D;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}